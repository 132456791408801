/* eslint-disable max-len */
<template>
  <div>
    <b-row class="text-center">
      <b-col>
        <h5>DX Spots - <b-badge>{{ sharedState.numberSpots }}</b-badge></h5>
      </b-col>
    </b-row>
    <b-row class="text-info">
      <b-col cols="5" class="text-center ml-3 px-1 font-weight-bold labels">Spot Filter</b-col>
      <b-col cols="3" class="text-center px-1 font-weight-bold labels">Spot Age</b-col>
      <b-col></b-col>
    </b-row>
    <b-row>
      <b-col cols="5" class="ml-3 px-1">
        <b-form-select
          v-model="sharedState.settings.spot_filter_default"
          size="sm"

          @change="spotFilterChange"
        >
          <b-form-select-option value="1">
            {{ sharedState.settings.spot_filter_one }}</b-form-select-option>
          <b-form-select-option value="2">
            {{ sharedState.settings.spot_filter_two }}</b-form-select-option>
          <b-form-select-option value="3">
            {{ sharedState.settings.spot_filter_three }}</b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="3" class="px-1">
        <b-form-select
          v-model="sharedState.settings.spot_age_default"
          :options="spotAgeOptions"
          size="sm"
          @change="spotFilterChange"
        >
        </b-form-select>
      </b-col>
      <b-col cols="1">
        <b-form-checkbox
          v-model="sharedState.showSpots"
          name="show-spots-switch"
          size="md"
          switch
          @change="showSpots"
          class="m-1"
          >Show
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EventBus from './eventbus';
import store from './store';

export default {
  data() {
    return {
      sharedState: store.state,
      spotAgeOptions: [
        { value: 5, text: '5 Min' },
        { value: 15, text: '15 Min' },
        { value: 30, text: '30 Min' },
        { value: 60, text: '60 Min' },
      ],
    };
  },
  methods: {
    spotFilterChange() {
      EventBus.$emit('spotFilterChange');
    },
    showSpots() {
      EventBus.$emit('showSpots');
    },
  },
};

</script>

<style scoped>
.labels {
  font-size: .8rem;
}
</style>
