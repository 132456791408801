<!-- eslint-disable max-len -->
<template>
  <validation-observer v-slot="{ handleSubmit }">
    <b-modal
      id="settings"
      size="lg"
      centered title="Settings"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      ok-only
      ok-title="Save"
      :cancel-disabled=cancelDisableStatus
      @ok.prevent="handleSubmit(onSubmit)"
      @cancel="handleSubmit(onCancel)"
    >
      <b-container fluid>
        <div>
          <b-form>
            <b-row>
              <b-col class="border rounded m-1">
                <validation-provider
                  name="Callsign"
                  :rules="{ required: true, min:3 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="callsign-input-group"
                    label="Callsign"
                    label-for="callsign-input"
                    description="Enter your callsign"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="callsign-input"
                      name="callsign-input"
                      v-model="sharedState.settings.callsign"
                      :state="getValidationState(validationContext)"
                      aria-describedby="callsign-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="callsign-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Username"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="username-input-group"
                    label="Username"
                    label-for="username-input"
                    description="Usually your first name"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="username-input"
                      name="username-input"
                      v-model="sharedState.settings.username"
                      :state="getValidationState(validationContext)"
                      aria-describedby="username-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="username-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Latitude"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="latitude-input-group"
                    label="Latitude"
                    label-for="lat-input"
                    description="Enter your latitude in xx.xx (e.g., 35.42) format"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="lat-input"
                      name="lat-input"
                      v-model="sharedState.settings.lat"
                      :state="getValidationState(validationContext)"
                      aria-describedby="lat-feedback"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-invalid-feedback id="lat-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
                <validation-provider
                  name="Longitude"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="longitude-input-group"
                    label="Longitude"
                    label-for="long-input"
                    description="Enter your longitude in xx.xx (e.g., -122.45) format"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="long-input"
                      name="long-input"
                      v-model="sharedState.settings.long"
                      :state="getValidationState(validationContext)"
                      aria-describedby="long-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="long-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Grid"
                  :rules="{ required: true, min:6 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="grid-input-group"
                    label="Grid"
                    label-for="grid-input"
                    description="Enter your grid in XXNNxx (e.g., CN87vj) format"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="grid-input"
                      name="grid-input"
                      v-model="sharedState.settings.grid"
                      :state="getValidationState(validationContext)"
                      aria-describedby="grid-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="grid-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col class="border rounded m-1">
                <validation-provider
                  name="Clublog Login"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="clublog-login-input-group"
                    label="Clublog Login"
                    label-for="clublog-login-input"
                    description="Enter your Clublog login"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="clublog-login-input"
                      name="clublog-login-input"
                      v-model="sharedState.settings.clublog_login"
                      :state="getValidationState(validationContext)"
                      aria-describedby="clublog-login-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="clublog-login-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Clublog Password"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="clublog-password-input-group"
                    label="Clublog Password"
                    label-for="clublog-password-input"
                    description="Enter Clublog password. An application-specific password is recommended"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="clublog-password-input"
                      name="clublog-password-input"
                      type="password"
                      v-model="sharedState.settings.clublog_password"
                      :state="getValidationState(validationContext)"
                      aria-describedby="clublog-password-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="clublog-password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="QRZ Login"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="qrz-login-input-group"
                    label="QRZ.com Login"
                    label-for="qrz-login-input"
                    description="Enter QRZ.com login"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="qrz-login-input"
                      name="qrz-login-input"
                      v-model="sharedState.settings.callbook_username"
                      :state="getValidationState(validationContext)"
                      aria-describedby="qrz-login-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="qrz-login-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Clublog Password"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="qrz-password-input-group"
                    label="QRZ.com Password"
                    label-for="qrz-password-input"
                    description="Enter Clublog password"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="qrz-password-input"
                      name="qrz-password-input"
                      type="password"
                      v-model="sharedState.settings.callbook_password"
                      :state="getValidationState(validationContext)"
                      aria-describedby="qrz-password-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="qrz-password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  id="spot-label-input-group"
                  label="Default Spot Settings"
                  description=""
                  class="font-weight-bold mb-0"
                >
                </b-form-group>
                <b-form-group
                  label-cols-sm="3"
                  label="Filter 1:"
                  label-align="right"
                  class="mb-1"
                >
                  <b-form-input
                    id="spot-label-one-input"
                    name="spot-label-one-input"
                    v-model="sharedState.settings.spot_filter_one"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="3"
                  label="Filter 2:"
                  label-align="right"
                  class="mb-1"
                >
                  <b-form-input
                    id="spot-label-two-input"
                    name="spot-label-two-input"
                    v-model="sharedState.settings.spot_filter_two"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="3"
                  label="Filter 3:"
                  label-align="right"
                  class="mb-1"
                >
                  <b-form-input
                    id="spot-label-three-input"
                    name="spot-label-three-input"
                    v-model="sharedState.settings.spot_filter_three"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="3"
                  label="Age:"
                  label-align="right"
                  class="mb-1"
                >
                  <b-form-select
                    v-model="sharedState.settings.spot_age_default"
                    :options="spotAgeOptions"
                    size="sm"
                  >
                  </b-form-select>
                </b-form-group>
                <b-row align-v="stretch">
                  <b-col cols="3" class="ml-auto m-2">
                    <b-button
                      v-if="$auth.isAuthenticated"
                      variant="danger"
                      size="md"
                      @click="logout()"
                      >Logout
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-container>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate';
import {
  required,
  min,
  numeric,
} from 'vee-validate/dist/rules';

import axios from 'axios';
import store from './store';
import EventBus from './eventbus';

extend('required', {
  ...required,
  message: '{_field_} is Required!',
  computesRequired: true,
});

extend('min', {
  ...min,
  params: ['length'],
  message: 'Minimum {length} Characters Required!',
});

extend('numeric', {
  ...numeric,
  message: 'Numbers Only Allowed Representing Lat/Lng in XX.XX Format, e.g., 35.32 or -122.25',
});

const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: 'settings',
  data() {
    return {
      sharedState: store.state,
      cancelDisableStatus: false,
      spotAgeOptions: [
        { value: 5, text: '5 Min' },
        { value: 15, text: '15 Min' },
        { value: 30, text: '30 Min' },
        { value: 60, text: '60 Min' },
      ],
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    logout() {
      const path = `${API_URL}/api/clear_session`;
      axios.get(path)
        .then((res) => {
          // eslint-disable-next-line no-console
          console.log(`clear_session completed with ${res.data}`);
        });
      this.$auth.logout();
      this.$router.push({ path: '/' });
    },
    onSubmit() {
      const path = `${API_URL}/api/user_settings`;
      const params = {
        settings: this.sharedState.settings,
      };
      axios.post(path, params)
        .then(() => {
          // this.sharedState.settings = res.data;
          EventBus.$emit('initialize');
          this.$bvModal.hide('settings');
        });
    },
    onCancel() {
      this.$bvModal.hide('settings');
    },
  },
};
</script>
