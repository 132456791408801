<template>
  <Loading v-if="$auth.isLoading" />
  <div v-else id="app">
    <router-view/>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading,
  },
};
</script>
