<template>
  <div v-if="sharedState.numberSpots > 0">
    <b-table
      sticky-header="716px"
      striped
      hover
      small
      head-variant="dark"
      :fields="fields"
      :items="sharedState.spotTableList"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      class="spots border rounded mr-0 mb-0 spottable-height">
      <template #cell(dx_cc)="data">
        <span v-html="data.value"></span>
      </template>
    </b-table>
    <div class="border rounded index-height">
      <b-container>
      <b-row class="spots">
        <b-col align-self="center">
          <b-row>
            <b-col cols="1" class="">
              <!-- <b-img src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png" height="16" width="16"></b-img> -->
              <b-img src="https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png" height="16" width="20"></b-img>
            </b-col>
            <b-col class="pl-1">
              <p class="my-0">Cluster Spots</p>
            </b-col>
          </b-row>
          <b-row>
          <b-col cols="1" class="">
              <b-img src="https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png" height="16" width="20"></b-img>
            </b-col>
            <b-col class="pl-1">
              <p class="my-0">RBN Spots</p>
            </b-col>
          </b-row>
          <b-row>
          <b-col cols="1" class="">
              <b-img src="https://maps.gstatic.com/mapfiles/ms2/micons/green-dot.png" height="16" width="20"></b-img>
            </b-col>
            <b-col class="pl-1">
              <p class="my-0">Pskreporter Spots</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="1" class="m-1 rounded verified-block">
            </b-col>
            <b-col class="pl-1">
              <p class="my-0">Confirmed on band</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1" class="m-1 rounded no-verified-block">
            </b-col>
            <b-col class="pl-1">
              <p class="my-0 no-verified">Worked, not confirmed on band</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1" class="m-1 rounded no-band-block">
            </b-col>
            <b-col  class="pl-1">
              <p class="my-0 no-band">Not worked on band</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1" class="m-1 rounded no-worked-block">
            </b-col>
            <b-col class="pl-1">
              <p class="my-0 no-worked">Not worked on any band (ATNO)</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      </b-container>
    </div>
  </div>
  <div v-else>
    <div class="spottable-height row align-items-center">
      <div class="col">
        <h5 class="text-center text-info">No Spots for the Chosen Filter</h5>
      </div>
    </div>
  </div>
</template>

<script>
import store from './store';

export default {
  name: 'spotTable',
  data() {
    return {
      sortBy: 'timestamp',
      sortDesc: true,
      fields: [
        {
          key: 'dxcall', label: 'DX', sortable: true, tdClass: 'font-weight-bold',
        },
        {
          key: 'dx_cc', label: '', formatter: 'getFlag', tdClass: 'pr-0',
        },
        {
          key: 'dx_name', label: 'Country', formatter: (value) => value.substring(1), sortable: true, tdClass: 'setDxccStatus',
        },
        {
          key: 'freq', sortable: true, tdClass: 'text-right font-weight-bold', thClass: 'text-center',
        },
        {
          key: 'bearing', label: 'Brg', sortable: false, tdClass: 'text-right', thClass: 'text-right',
        },
        {
          key: 'spotcall', label: 'de', sortable: false, tdClass: 'de text-left text-truncate', thClass: 'text-center',
        },
        {
          key: 'source', label: 'Src', formatter: (value) => value.charAt(0).toUpperCase(), sortable: true, tdClass: 'text-center text-truncate', thClass: 'text-center',
        },
        {
          key: 'timestamp', label: 'Z', formatter: 'utcTime', sortable: true, tdClass: 'text-right', thClass: 'text-center',
        },
      ],
      sharedState: store.state,
    };
  },
  methods: {
    testTable() {

    },
    utcTime(value) {
      const time = new Date(value);
      let hours = time.getUTCHours().toString();
      let min = time.getUTCMinutes().toString();
      if ((hours.length === 1)) hours = `0${hours}`;
      if ((min.length === 1)) min = `0${min}`;
      const UTCTime = `${hours}:${min}`;
      return UTCTime;
    },
    getFlag(value) {
      const cc = value.toLowerCase(); // TypeError rasied if value is null - FIX
      // const url = `https://www.countryflags.io/${value}/flat/16.png`;
      const url = `https://flagcdn.com/w20/${cc}.png`;
      // const errorflag = ' onerror=this.onerror=null;this.src=\'./question-mark-16.png\';';
      // const flag = `<img src=${url}${errorflag} />`;
      const flag = `<img src=${url} />`;
      // eslint-disable-next-line no-console
      // console.log(flag);
      return flag;
    },
    setDxccStatus(value) {
      let colorStatus = '';
      if (value.charAt(0) === '3') { // not worked (ATNO) (red)
        colorStatus = 'dx text-truncate font-weight-bold';
      }
      if (value.charAt(0) === '2') { // worked but not verified (yellow)
        colorStatus = 'dx text-truncate font-weight-bold no-verified';
      }
      if (value.charAt(0) === '1') { // verified country (black)
        colorStatus = 'dx text-truncate font-weight-bold no-worked';
      }
      if (value.charAt(0) === '4') { // not worked and/or verified for this band (purple)
        colorStatus = 'dx text-truncate font-weight-bold no-band';
      }
      return colorStatus;
    },
  },
};
</script>

<style>
.spots {
  font-size: .75rem;
}
.de {
  max-width: 50px;
}
.dx {
  max-width: 100px;
}
.verified-block {
  background-color: black;
}
.no-verified {
  color: rgb(255, 115, 0);
}
.no-verified-block {
  background-color: rgb(255, 115, 0);
}
.no-worked {
  color: rgb(255, 0, 0);
}
.no-worked-block {
  background-color: rgb(255, 0, 0);
}
.no-band {
  color: rgb(195, 0, 255)
}
.no-band-block {
  background-color: rgb(195, 0, 255)
}
/* .spottable-height {
  height: 718px;
} */
.spottable-height {
  height: 642px;
}
.index-height {
  margin-top: 2px;
  height: 74px;
}
</style>
