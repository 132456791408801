<template>
  <div>
    <b-row class="text-center">
      <b-col>
        <h5 class="mb-1">
          <div>
            {{ sharedState.settings.username }}, {{ sharedState.settings.callsign }}
            <b-icon-gear v-b-modal.settings font-scale="1" class="float-right mt-1">
            </b-icon-gear>
          </div>
        </h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from './store';

export default {
  name: 'user',
  data() {
    return {
      sharedState: store.state,
    };
  },
};
</script>
