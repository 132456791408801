<template>
  <div>
    <b-overlay :show="sharedState.isLoading" opacity="0.6">
      <div v-if="$auth.isAuthenticated">
        <settings></settings>
        <b-container fluid class="mx-2">
          <b-row class="mr-1 mt-2 my-0" id="radioAndDisplaySettings">
            <b-col cols="4" class="text-center border-left border-right">
              <p class="text-left mb-0">v2.12</p>
              <h5>Radio Controls go here</h5>
            </b-col>
            <b-col class="pb-0">
              <clock></clock>
            </b-col>
            <b-col class="border-left">
              <dxcc></dxcc>
            </b-col>
            <b-col cols="3" class="border-left border-right">
              <spots></spots>
            </b-col>
          </b-row>
          <b-row class="mb-1 mr-1">
            <b-col class="p-0">
              <div id="map" ref="map" class="rounded"></div>
            </b-col>
            <b-col class="rounded pr-0 ml-1 pl-0" id="spotTable">
              <spotTable></spotTable>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from 'axios';
import clock from './clock.vue';
import dxcc from './dxcc.vue';
import spots from './spots.vue';
import spotTable from './spotTable.vue';
import store from './store';
import EventBus from './eventbus';
import settings from './settings.vue';

// const { API_URL } = process.env;
// const API_URL = 'http://127.0.0.1:5000';
// const API_URL = 'https://vdx-test.bercow.com';
const API_URL = process.env.VUE_APP_API_URL;
console.log(process.env);

axios.defaults.withCredentials = true;

export default {
  name: 'vDX',
  data() {
    return {
      map: null,
      sharedState: store.state,
      dxccMarkers: [],
      activeInfoWindow: {},
      currentSpots: {},
      spotInterval: 20000,
      date: null,
      sun_position: null,
      earth_radius_meters: 6371008,
      marker_twilight_civil: null,
      marker_twilight_nautical: null,
      marker_twilight_astronomical: null,
      marker_night: null,
    };
  },
  components: {
    dxcc,
    spots,
    clock,
    spotTable,
    settings,
  },
  methods: {
    getSpots() {
      this.deleteAgedSpots();
      const path = `${API_URL}/api/get_spots`;
      const params = {
        filter: this.sharedState.settings.spot_filter_default,
        spot_age: this.sharedState.settings.spot_age_default,
      };
      const iconBaseUrl = 'https://maps.gstatic.com/mapfiles/ms2/micons/';
      // const iconBaseUrl = 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless2.png'
      const icon = {
        cluster: {
          iconColor: {
            url: `${iconBaseUrl}red-dot.png`,
            // url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png', if using set scale to 30,30
            scaledSize: new window.google.maps.Size(40, 40),
            labelOrigin: new window.google.maps.Point(11, -5),
          },
        },
        rbn: {
          iconColor: {
            url: `${iconBaseUrl}blue-dot.png`,
            scaledSize: new window.google.maps.Size(40, 40),
            labelOrigin: new window.google.maps.Point(22, -5),
          },
        },
        pskreporter: {
          iconColor: {
            url: `${iconBaseUrl}green-dot.png`,
            scaledSize: new window.google.maps.Size(40, 40),
            labelOrigin: new window.google.maps.Point(22, -5),
          },
        },
      };
      axios.post(path, params)
        .then((res) => {
          this.rawSpots = res.data;
        })
        .then(() => {
          Object.keys(this.rawSpots).forEach((spot) => {
            const rawSpot = this.rawSpots[spot];
            const spotKey = `${rawSpot.dxcall}-${rawSpot.band}`;
            const currentSpot = this.currentSpots[spotKey];
            if (spotKey in this.currentSpots) {
              currentSpot.time = rawSpot.time;
              currentSpot.timestamp = rawSpot.timestamp;
              currentSpot.spotcall = rawSpot.spotcall;
              currentSpot.freq = rawSpot.freq;
              currentSpot.source = rawSpot.source;
              return;
            }
            // end new code
            const dxPosition = {
              lat: parseFloat(rawSpot.dx_lat),
              lng: parseFloat(rawSpot.dx_long),
            };
            const spotMarker = new window.google.maps.Marker({
              position: dxPosition,
              animation: window.google.maps.Animation.DROP,
              label: {
                text: rawSpot.dxcall,
                color: 'Blue',
                fontSize: '14px',
                fontWeight: 'Bold',
              },
              icon: icon[rawSpot.source].iconColor,
              title: `${rawSpot.dxcall}, ${rawSpot.dx_name} - ${rawSpot.freq} - ${rawSpot.bearing}`,
              visible: this.sharedState.showSpots,
              map: this.map,
            });
            window.google.maps.event.addListener(spotMarker, 'mouseover', () => {
              const xpath = [
                { lat: this.sharedState.settings.lat - 3, lng: this.sharedState.settings.long + 3 },
                dxPosition,
              ];
              const polyPath = new window.google.maps.Polyline({
                path: xpath,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeWeight: 1,
              });
              polyPath.setMap(this.map);
              window.google.maps.event.addListener(spotMarker, 'mouseout', () => {
                polyPath.setMap(null);
              });
            });
            const content = {
              type: 'spot',
              data: {
                dxcall: rawSpot.dxcall,
                dx_cc: rawSpot.dx_cc,
                name: rawSpot.name,
                dx_name: rawSpot.dx_name,
                freq: rawSpot.freq,
                comment: rawSpot.comment,
                grid: rawSpot.grid,
                bearing: rawSpot.bearing,
                time: rawSpot.time,
                spotcall: rawSpot.spotcall,
                source: rawSpot.source,
              },
            };
            this.infoWindowContent(spotMarker, content);
            rawSpot.dx_name = this.dxccStatus(
              rawSpot.dx_name,
              rawSpot.freq,
              rawSpot.adif,
            );
            rawSpot.marker = spotMarker;
            this.currentSpots[spotKey] = rawSpot;
          });
        })
        .then(() => {
          const table = [];
          Object.values(this.currentSpots).forEach((spot) => {
            table.push(spot);
          });
          this.sharedState.spotTableList = table;
          this.sharedState.numberSpots = Object.keys(this.currentSpots).length;
          this.sharedState.isLoading = false;
        });
    },
    dxccStatus(name, freq, adif) {
      let band = '';
      switch (true) {
        case (freq < 2000):
          band = '160';
          break;
        case (freq < 4000):
          band = '80';
          break;
        case (freq < 6000):
          band = '60';
          break;
        case (freq < 7300):
          band = '40';
          break;
        case (freq < 10150):
          band = '30';
          break;
        case (freq < 14350):
          band = '20';
          break;
        case (freq < 18168):
          band = '17';
          break;
        case (freq < 21450):
          band = '15';
          break;
        case (freq < 24990):
          band = '12';
          break;
        case (freq < 29700):
          band = '10';
          break;
        case (freq < 54000):
          band = '6';
          break;
        default:
      }
      let dxccStatus = `1${name}`; // 1 = not worked (ATNO) (red)
      if (adif in this.dxccs) {
        dxccStatus = `4${name}`; // 4 = not worked OR verified for band (purple)
        if (band in this.dxccs[adif].bands) {
          if (this.dxccs[adif].bands[band] === 3 || this.dxccs[adif].bands[band] === 1) {
            dxccStatus = `3${name}`; // 3 = verified (black)
          } else {
            dxccStatus = `2${name}`; // 2 = worked, but not verified (yellow)
          }
        }
      }
      return dxccStatus;
    },
    async getDxcc() {
      const path = `${API_URL}/api/DXCC`;
      const url = 'https://maps.google.com/mapfiles/kml/paddle/blu-circle.png';
      await axios.get(path)
        .then((res) => {
          this.dxccs = res.data;
        })
        .then(() => {
          console.log(this.dxccs);
          Object.keys(this.dxccs).forEach((key) => {
            const dxccEntity = this.dxccs[key];
            const dxPosition = {
              lat: parseFloat(dxccEntity.lat),
              lng: parseFloat(dxccEntity.long),
            };
            const icon = {
              url,
              scaledSize: new window.google.maps.Size(30, 30),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(0, 0),
            };
            const dxccMarker = new window.google.maps.Marker({
              position: dxPosition,
              // animation: window.google.maps.Animation.DROP,
              icon,
              title: dxccEntity.name,
              visible: this.sharedState.showDXCC,
              map: this.map,
            });
            const content = {
              type: 'country',
              data: dxccEntity.name,
            };
            this.infoWindowContent(dxccMarker, content);
            this.map.addListener('zoom_changed', () => {
              if (this.map.getZoom() > 4) {
                const label = {
                  text: dxccEntity.name,
                  color: 'BLUE',
                  fontWeight: 'Bold',
                  fontSize: '14px',
                };
                dxccMarker.setLabel(label);
              } else {
                dxccMarker.setLabel(null);
              }
            });
            this.dxccMarkers.push(dxccMarker);
          });
        })
        .then(() => {
          this.sharedState.numberDXCC = this.dxccMarkers.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showDXCC() {
      Object.keys(this.dxccMarkers).forEach((marker) => {
        this.dxccMarkers[marker].setVisible(this.sharedState.showDXCC);
      });
    },
    infoWindowContent(marker, content) {
      const infowindow = new window.google.maps.InfoWindow({
        content: '',
      });
      window.google.maps.event.addListener(marker, 'click', () => {
        if (Object.keys(this.activeInfoWindow).length !== 0) {
          this.activeInfoWindow.close();
        }
        axios.post(`${API_URL}/api/infowindowContent`, content)
          .then((res) => {
            const dxccContent = res.data;
            this.map.panTo(marker.getPosition());
            this.map.setZoom(5);
            infowindow.setContent(dxccContent);
            infowindow.open(marker.get('map'), marker);
            window.google.maps.event.addListener(infowindow, 'closeclick', () => {
              this.map.panTo({
                lat: 5,
                lng: this.sharedState.settings.long,
              });
              this.map.setZoom(2);
            });
            this.activeInfoWindow = infowindow;
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    calcSpotAge(spotTime) {
      const now = Date.now();
      let age = (now - spotTime) / 60000;
      age = Math.abs(Math.round(age));
      return age;
    },
    deleteAgedSpots() {
      try {
        Object.keys(this.currentSpots).forEach((spot) => {
          const diff = this.calcSpotAge(this.currentSpots[spot].timestamp);
          if ((diff) > this.sharedState.settings.spot_age_default) {
            this.currentSpots[spot].marker.setMap(null);
            delete this.currentSpots[spot];
          }
        });
      } catch (err) {
        console.log('type error as expected', err.message);
      }
    },
    spotFilterChange() {
      this.sharedState.isLoading = true;
      clearInterval(this.spotTimer);
      Object.keys(this.currentSpots).forEach((spot) => {
        this.currentSpots[spot].marker.setMap(null);
        delete this.currentSpots[spot];
      });
      this.sharedState.spotTableList = [];
      this.getSpots();
      this.spotTimer = setInterval(this.getSpots, this.spotInterval);
    },
    showSpots() {
      Object.keys(this.currentSpots).forEach((spot) => {
        this.currentSpots[spot].marker.setVisible(this.sharedState.showSpots);
      });
    },
    async getUserSettings() {
      this.sharedState.settings.email = this.$auth.user.email;
      await axios.post(`${API_URL}/api/initialize`, this.sharedState.settings)
        .then((res) => {
          this.sharedState.settings = res.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.$bvModal.show('settings');
        });
    },
    initGreyline() {
      this.sun_position = this.calculatePositionOfSun();

      this.marker_twilight_civil = new window.google.maps.Circle({
        map: this.map,
        center: this.getShadowPosition(),
        radius: this.getShadowRadiusFromAngle(0.566666),
        fillColor: '#000',
        fillOpacity: 0.3,
        strokeOpacity: 0,
        clickable: false,
        editable: false,
      });
      this.marker_twilight_nautical = new window.google.maps.Circle({
        map: this.map,
        center: this.getShadowPosition(),
        radius: this.getShadowRadiusFromAngle(2), // note that this angle is not from the original code which was 6
        fillColor: '#000',
        fillOpacity: 0.1,
        strokeOpacity: 0,
        clickable: false,
        editable: false,
      });
    },
    moveGreyline() {
      this.sun_position = this.calculatePositionOfSun();
      this.marker_twilight_civil.setCenter(this.getShadowPosition());
      this.marker_twilight_nautical.setCenter(this.getShadowPosition());
    },
    getShadowRadiusFromAngle(angle) {
      const shadow_radius = this.earth_radius_meters * Math.PI * 0.5;
      const twilight_dist = ((this.earth_radius_meters * 2 * Math.PI) / 360) * angle;
      return shadow_radius - twilight_dist;
    },
    getShadowPosition() {
      return (this.sun_position) ? new window.google.maps.LatLng(-this.sun_position.lat(), this.sun_position.lng() + 180) : null;
    },
    jday(date) {
      return (date.getTime() / 86400000.0) + 2440587.5;
    },
    calculatePositionOfSun(date) {
      // eslint-disable-next-line no-param-reassign
      date = new Date();

      const rad = 0.017453292519943295;

      // based on NOAA solar calculations
      const ms_past_midnight = ((date.getUTCHours() * 60 + date.getUTCMinutes()) * 60 + date.getUTCSeconds()) * 1000 + date.getUTCMilliseconds();
      const jc = (this.jday(date) - 2451545) / 36525;
      const mean_long_sun = (280.46646 + jc * (36000.76983 + jc * 0.0003032)) % 360;
      const mean_anom_sun = 357.52911 + jc * (35999.05029 - 0.0001537 * jc);
      const sun_eq = Math.sin(rad * mean_anom_sun) * (1.914602 - jc * (0.004817 + 0.000014 * jc)) + Math.sin(rad * 2 * mean_anom_sun) * (0.019993 - 0.000101 * jc) + Math.sin(rad * 3 * mean_anom_sun) * 0.000289;
      const sun_true_long = mean_long_sun + sun_eq;
      const sun_app_long = sun_true_long - 0.00569 - 0.00478 * Math.sin(rad * 125.04 - 1934.136 * jc);
      const mean_obliq_ecliptic = 23 + (26 + ((21.448 - jc * (46.815 + jc * (0.00059 - jc * 0.001813)))) / 60) / 60;
      const obliq_corr = mean_obliq_ecliptic + 0.00256 * Math.cos(rad * 125.04 - 1934.136 * jc);

      const lat = Math.asin(Math.sin(rad * obliq_corr) * Math.sin(rad * sun_app_long)) / rad;

      const eccent = 0.016708634 - jc * (0.000042037 + 0.0000001267 * jc);
      const y = Math.tan(rad * (obliq_corr / 2)) * Math.tan(rad * (obliq_corr / 2));
      const rq_of_time = 4 * ((y * Math.sin(2 * rad * mean_long_sun) - 2 * eccent * Math.sin(rad * mean_anom_sun) + 4 * eccent * y * Math.sin(rad * mean_anom_sun) * Math.cos(2 * rad * mean_long_sun) - 0.5 * y * y * Math.sin(4 * rad * mean_long_sun) - 1.25 * eccent * eccent * Math.sin(2 * rad * mean_anom_sun)) / rad);
      const true_solar_time_in_deg = ((ms_past_midnight + rq_of_time * 60000) % 86400000) / 240000;

      const lng = -((true_solar_time_in_deg < 0) ? true_solar_time_in_deg + 180 : true_solar_time_in_deg - 180);

      return new window.google.maps.LatLng(lat, lng);
    },
    initializeMap() {
      this.map = new window.google.maps.Map(this.$refs.map, {
        zoom: 2,
        center: {
          lat: 5,
          lng: parseFloat(this.sharedState.settings.long),
        },
      });
    },
    async initialize() {
      this.sharedState.isLoading = true;
      this.dxccMarkers = [];
      this.activeInfoWindow = {};
      this.currentSpots = {};
      this.numberSpots = 0;
      this.numberDXCC = 0;
      this.spotTableList = [];
      await this.getUserSettings()
        .then(() => {
          this.initializeMap();
        });
      const icon = {
        url: 'https://maps.google.com/mapfiles/kml/shapes/ranger_station.png',
        scaledSize: new window.google.maps.Size(20, 20),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(0, 0),
      };
        // eslint-disable-next-line no-new
      new window.google.maps.Marker({
        position: {
          lat: parseFloat(this.sharedState.settings.lat),
          lng: parseFloat(this.sharedState.settings.long),
        },
        animation: window.google.maps.Animation.DROP,
        title: `Home - ${this.sharedState.settings.callsign}`,
        icon,
        map: this.map,
      });
      this.initGreyline();
      await this.getDxcc();
      this.getSpots();
      this.spotTimer = setInterval(this.getSpots, this.spotInterval);
      this.greyLine = setInterval(this.moveGreyline, 60000);
      this.sharedState.isLoading = false;
    },
  },
  created() {
    EventBus.$on('showDXCC', () => {
      this.showDXCC();
    });
    EventBus.$on('spotFilterChange', () => {
      this.spotFilterChange();
    });
    EventBus.$on('showSpots', () => {
      this.showSpots();
    });
    EventBus.$on('initialize', () => {
      this.initialize();
    });
    this.initialize();
  },
};
</script>

<style scoped>
.light-bg {
  background-color:rgb(220, 227, 236);
}

#map {
  height: 718px;
  width: 1024px;
}

/* #map {
  height: 1200px;
  width: 2048px;
}
 */
#spotTable {
  min-width: 100px;
}

#spotDetail {
  height: 100%;
}

</style>
