<template>
  <div>
    <b-row class="text-center">
      <b-col>
        <h5>DXCC - <b-badge>{{ sharedState.numberDXCC }}</b-badge></h5>
      </b-col>
    </b-row>
    <b-row class="label-height mb-0"></b-row>
    <b-row>
      <b-col class="text-center">
        <b-form-checkbox
          v-model="sharedState.showDXCC"
          name="show-dxcc-switch"
          size="md"
          switch
          @change="showDXCC"
          >Show Markers</b-form-checkbox
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EventBus from './eventbus';
import store from './store';

export default {
  data() {
    return {
      sharedState: store.state,
    };
  },
  methods: {
    showDXCC() {
      EventBus.$emit('showDXCC');
    },
  },
};

</script>

<style scoped>
.label-height {
  height: 19px;
}

</style>
