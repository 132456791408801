<template>
  <vDX v-if='$auth.isAuthenticated' />
</template>

<script>

import vDX from '@/components/vDX.vue';

export default {
  name: 'Home',
  components: {
    vDX,
  },
};
</script>
