<template>
  <div>
    <b-row class="text-center">
      <b-col>
        <user></user>
      </b-col>
    </b-row>
    <b-row class="label-height">
    </b-row>
    <b-row class="mb-0 pb-0">
      <b-col>
        <b-card-group class="mb-0 pb-0">
          <b-card no-body bg-variant="dark" class="clocks rounded mb-0">
            <b-card-body class="m-0 p-0">
              <b-card-text class="text-center time-title m-0">UTC</b-card-text>
              <b-card-text class="text-center time m-0 pb-0">{{ utc }}</b-card-text>
            </b-card-body>
          </b-card>
          <b-card no-body bg-variant="dark" class="clocks rounded mb-0 ml-1">
            <b-card-body class="m-0 p-0">
              <b-card-text class="text-center time-title m-0">Local</b-card-text>
              <b-card-text class="text-center time m-0 pb-0">{{ localtime }}</b-card-text>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import user from './user.vue';

export default {
  name: 'clock',
  data() {
    return {
      localtime: '',
      utc: '',
    };
  },
  components: {
    user,
  },
  methods: {
    calcLocaltime() {
      const d = new Date();
      let s = d.getSeconds();
      let m = d.getMinutes();
      let h = d.getHours();
      if (h < 10) { h = `0${h}`; }
      if (m < 10) { m = `0${m}`; }
      if (s < 10) { s = `0${s}`; }
      const ampm = this.checkAMPM(h);
      if (h > 12) h -= 12;
      this.localtime = `${h}:${m}:${s}${ampm}`;
    },
    checkAMPM(i) {
      return (i < 12 ? 'a' : 'p');
    },
    calcUTCtime() {
      const d = new Date();
      let s = d.getUTCSeconds();
      let m = d.getUTCMinutes();
      let h = d.getUTCHours();
      if (h < 10) { h = `0${h}`; }
      if (m < 10) { m = `0${m}`; }
      if (s < 10) { s = `0${s}`; }
      this.utc = `${h}:${m}:${s}z`;
    },
  },
  created() {
    setInterval(this.calcLocaltime, 1000);
    setInterval(this.calcUTCtime, 1000);
  },
};
</script>

<style scoped>

.label-height {
  height: 10px;
}

.time {
  font-size: 1rem;
  font-weight: 600;
  vertical-align: middle;
  margin: 0px;
  color: #39ff14;
}

.time-title {
  font-size: .8rem;
  font-weight: 200;
  vertical-align: middle;
  margin: 0px;
  color: #39ff14;
}

#clock-section {
  height: 100px;
}

.clocks {
  width: 125px;
}

</style>
