const store = {
  state: {
    isLoading: false,
    numberDXCC: 0,
    numberSpots: 0,
    settings: {
      callsign: '',
      email: '',
      username: '',
      lat: 35,
      long: -122,
      grid: '',
      dxcc: 291,
      entity: 'United States of America',
      clublog_login: '',
      clublog_password: '',
      callbook_username: '',
      callbook_password: '',
      use_clublog: true,
      use_qrz: true,
      use_hamqth: false,
      use_qrzcq: false,
      spot_age_default: 30,
      spot_filter_default: 2,
      spot_filter_one: '',
      spot_filter_two: '',
      spot_filter_three: '',
      spot_filter_four: '',
      spot_filter_five: '',
    },
    showDXCC: false,
    showSpots: true,
    spotTableList: [],
  },
};

export default store;
