import { render, staticRenderFns } from "./vDX.vue?vue&type=template&id=f29673b8&scoped=true&"
import script from "./vDX.vue?vue&type=script&lang=js&"
export * from "./vDX.vue?vue&type=script&lang=js&"
import style0 from "./vDX.vue?vue&type=style&index=0&id=f29673b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f29673b8",
  null
  
)

export default component.exports